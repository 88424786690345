body, html {
    margin: 0;
    padding: 0;
}

#employeeCensus .ra-input:nth-child(3),
#employeeCensus .ra-input:nth-child(5),
.ra-input-children .ra-input:nth-child(1),
.ra-input-children .ra-input:nth-child(3),
.ra-input-birthdate,
.ra-input-age,
.ra-input-spouseBirthdate,
.ra-input-spouseAge
{
    display: inline-block;
}
#employeeCensus .ra-input:nth-child(4),
.ra-input-children .ra-input:nth-child(2),
.ra-input-undefined{
    display: inline-block;
    line-height: 4;
    vertical-align: top;
}

#employeeCensus .ra-input:nth-child(6){
    height: 0;
}

#employeeCensus .ra-input:nth-child(7),
#employeeCensus .ra-input:nth-child(9){
    display: inline-block;
}
#employeeCensus .ra-input:nth-child(8){
    display: inline-block;
    line-height: 4;
    vertical-align: top;
}

label.MuiFormLabel-root span{
    font-size: 16px;
}

#employeeCensus #children .ra-input{
    display: inline-block;
}
#employeeCensus #children .ra-input:nth-child(2){
    vertical-align: top;
    line-height: 4;
}

.MuiTableCell-head{
    font-weight: bold !important;
}

.newEmployee{
    text-align: center;
    padding: 10px 0px;
}

.selectStatus{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    float: right;
    padding: 10px;
}
.fullWidth{
    display: inline-flex;
    width: 100%;
    margin-top: 15px;
}
.fullWidth .MuiTypography-body1, .fullWidth{
    font-weight: bold !important;
    color: rgba(0, 0, 0, 0.87) !important;
}
.noLineHeight{
    line-height: 0;
}
.buttonsBottom{
    padding: 20px;
}
.buttonsBottom button{
    margin-right: 20px;
}